<template>
  <div @click="$emit('select', exercise)" class="d-flex align-items-center">
    <ExerciseBadge
      v-if="!isSection"
      :exercise="exercise"
      :exercises="workoutSession.exercises"
      class="flex-shrink-0 me-3"
    ></ExerciseBadge>

    <div class="flex-grow-1">
      <div>
        <span
          :class="isSection ? 'fw-bold' : 'fs-5'"
          class="me-2 lh-sm"
        >{{ exercise.name }}</span>
        <ExerciseStatus
          v-if="workoutIsStarted || workoutIsFinished"
          :isCompleted="isCompleted"
        ></ExerciseStatus>
      </div>

      <TextView
        v-if="exercise.description"
        :text="exercise.description"
        class="smaller text-muted mt-1"
      ></TextView>

      <ExerciseSetRep
        :sets="$options.filters.exerciseSetParsed(exercise)"
        class="mt-1"
      ></ExerciseSetRep>

      <TextView
        v-if="note && note !== ''"
        :text="note"
        :useQuote="true"
        class="small mt-2"
      ></TextView>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'WorkoutExerciseListItem',
  components: {
    ExerciseBadge: () => import('@/components/ExerciseBadge'),
    ExerciseStatus: () => import('@/components/ExerciseStatus'),
    TextView: () => import('@/components/TextView'),
    ExerciseSetRep: () => import('@/components/ExerciseSetRep'),
  },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('workout', ['workoutSession', 'workoutExerciseStatuses']),
    ...mapGetters('workout', ['workoutIsStarted', 'workoutIsFinished']),
    isSection() {
      return this.exercise.type === 'section';
    },
    note() {
      const exerciseStatus = this.workoutExerciseStatuses.find((status) => {
        const { exerciseId } = status;
        return exerciseId === this.exercise.exerciseId;
      });
      if (exerciseStatus && exerciseStatus.note && exerciseStatus.note !== '') return exerciseStatus.note;
      return null;
    },
    exerciseStatus() {
      const { exercise, workoutExerciseStatuses } = this;
      return workoutExerciseStatuses.find((status) => status.exerciseId === exercise.exerciseId);
    },
    isCompleted() {
      if (!this.exerciseStatus || this.exerciseStatus.isCompleted === null) return null;
      return !!this.exerciseStatus.isCompleted;
    },
  },
};
</script>

<style lang="scss" scoped>
blockquote {
  margin-top: .75rem;
  margin-bottom: 0;
}
</style>
